<template>
  <div id="app">
    <router-view />
    <!-- <div class="app_zzc" v-show="cardTab"></div> -->
    <div
      @touchmove.prevent
      v-show="cardTab"
      ref="card_tab"
      class="card_tab"
      @click="toCard"
      @touchmove="touchMove"
      @touchend="touchEnd"
    >
      <div></div>
      <div>酷影元名片</div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  name: "App",
  data() {
    return {
      cardTab: false,
      touchX: 0, //移动端点击时距左边的距离
      touchY: 0, //移动端点击时距右边的距离
    };
  },
  components: {
    // Tabbar,
  },
  methods: {
    toCard() {
      wx.miniProgram.redirectTo({
        url: "../addExhibition/addExhibition",
        success: function (res) {
          console.log(res);
        },
      });
    },
    //手指拖动事件
    touchMove(e) {
      let card_tab = this.$refs.card_tab;
      if (this.touchX == 0) {
        this.touchX = e.changedTouches[0].pageX - card_tab.offsetLeft;
      }
      if (this.touchY == 0) {
        this.touchY = e.changedTouches[0].pageY - card_tab.offsetTop;
      }
      //用手指的位置减去手指相对元素的位置，得到元素的位置
      let left = e.changedTouches[0].pageX - this.touchX;
      let top = e.changedTouches[0].pageY - this.touchY;
      if (left < 0) {
        left = 0;
      }
      if (left > window.innerWidth - card_tab.offsetWidth) {
        left = window.innerWidth - card_tab.offsetWidth;
      } //可视区域宽度
      if (top < 0) {
        top = 0;
      }
      if (top > window.innerHeight - card_tab.offsetHeight) {
        top = window.innerHeight - card_tab.offsetHeight;
      } //可视区域高度

      //移动当前元素
      card_tab.style.left = left + "px";
      card_tab.style.top = top + "px";
    },
    //手指抬起
    touchEnd() {
      this.touchX = 0;
      this.touchY = 0;
    },
    isWx() {
      var that = this;
      // console.log(12122);
      var ua = navigator.userAgent.toLowerCase();

      console.log(ua, "33333333333333");

      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return new Promise((resolve) => {
          wx.miniProgram.getEnv(function (res) {
            if (res.miniprogram) {
              console.log("在小程序里");
              that.cardTab = true;
              // resolve("mini-wx");
              // window.showtabbar = false;//从小程序打开的公众号页面隐藏底部tabbar
              // console.log(window.showtabbar, '66859');
            } else {
              console.log("在微信里");
              // resolve("wx");
            }
          });
        });
      } else {
        console.log("不在微信里");
        return new Promise((resolve) => {
          // resolve("no-wx");
        });
      }
    },
  },
  created() {
    // console.log(this.showtabbar, '9956')
    console.log(2222333);
    this.isWx();
    // if (window.__wxjs_environment === "miniprogram") {
    //   this.cardTab = true;
    // }
  },
  mounted() {
    // console.log(window.__wxjs_environment, 'APP_sss')
    // if (window.__wxjs_environment === "miniprogram") {
    //   this.cardTab = true;
    // }

    window.onload = function () {
      document.addEventListener("touchstart", function (e) {
        if (e.touches.length > 1) {
          e.preventDefault();
        }
      });
      document.addEventListener("gesturestart", function (e) {
        e.preventDefault();
      });
    };
  },
};
</script>

<style>
* {
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none;

  -webkit-user-select: none;

  -khtml-user-select: none;

  -moz-user-select: none;

  -ms-user-select: none;

  user-select: none;
}
html {
  font-size: 13.3333vw;
}
body {
  font-size: 0.16rem;
}
.card_tab {
  position: fixed;
  bottom: 6rem;
  right: 0.1rem;
  width: 2rem;
  height: 0.72rem;
  border-radius: 0.34rem;
  background-color: rgb(38, 112, 255);
  font-size: 0.3rem;
  display: flex;
  line-height: 0.72rem;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.14rem;
  color: white;
  z-index: 10086;
}
.card_tab > div:first-child {
  width: 0.46rem;
  height: 0.46rem;
  background-image: url("./assets/tabbar/card.png");
  background-repeat: no-repeat;
  background-size: cover;
}
input {
  -webkit-user-select: auto;
  -webkit-user-select: text !important;
}
</style>
