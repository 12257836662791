<template>
  <div class="home">
    <section>
      <!-- <div class="search" @click="goToSearch()">
        <img src="../../src/assets/tabbar/search.png" mode="" />
        <input
          type=""
          placeholder="搜索数字展厅"
          placeholder-style="color:#FFFEFE"
          class="ipt"
        />
      </div> -->
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <div v-for="(item, index) in bannerList" :key="index">
          <van-swipe-item><img :src="item.imgUrl" alt="" /></van-swipe-item>
        </div>
      </van-swipe>
    </section>
    <div class="center-list">
      <div class="radio-box"></div>
      <div
        class="list-item"
        v-for="(item, index) in listItem"
        :key="index"
        @click="goToDetail(item.index, item.id, item.name)"
      >
        <img :src="item.url" mode="" />
        <div class="name">{{ item.name }}</div>
      </div>
    </div>
    <div class="bottom-countant">
      <div class="title-count">
        <div class="title-left-blue"></div>
        <div class="title">我的元宇宙</div>
      </div>
      <div class="list-contant">
        <div class="content" v-for="(item, index) in exhList" :key="index">
          <div class="web-item">
            <div class="img-box">
              <img
                :src="item.tem_coverimg_dy ? item.tem_coverimg_dy : item.image"
                mode=""
                @click="toWebview(item.web_url, item.id)"
              />
            </div>

            <div class="bottom-contant">
              <div class="contant-name">
                {{ item.tem_name_dy ? item.tem_name_dy : item.title }}
              </div>
              <div class="contant-publisher">
                发布者:{{ item.publish_person ? item.publish_person : "匿名" }}
              </div>
              <div class="state">
                <div class="state-liulan state-contant">
                  <img src="../assets/tabbar/liulan.png" mode="" />
                  <div class="count">
                    {{ item.visit_person ? item.visit_person : 0 }}
                  </div>
                </div>
                <div class="state-shoucang state-contant">
                  <img src="../assets/tabbar/shoucang.png" mode="" />
                  <div class="count">
                    {{ item.collect_person ? item.collect_person : 0 }}
                  </div>
                </div>
              </div>
            </div>
            <img
              class="is-shoucang"
              :src="
                item.is_collect == 1
                  ? require('../assets/tabbar/collect.png')
                  : require('../assets/tabbar/z1.png')
              "
              @click="collect(index, item)"
            />
          </div>
        </div>
      </div>
    </div>
    <Tabbar v-show="showtabbar" />
  </div>
</template>

<script>
import $api from "../untils/api";
import { Toast } from "vant";
import Tabbar from "@/components/Tabbar.vue";
import wx from "weixin-js-sdk";
export default {
  name: "HomeView",
  components: { Tabbar },
  data() {
    return {
      bannerList: [""], //banner
      exhList: [],
      token: localStorage.getItem("token") || "",
      pageNum: {
        page: 1,
        pageSize: 6,
      },
      totalCount: 0,
      listItem: [
        {
          url: require("../assets/tabbar/zu11.png"),
          name: "企业馆",
          index: 1,
          id: 14,
        },
        {
          url: require("../assets/tabbar/zu22.png"),
          name: "科技馆",
          index: 2,
          id: 15,
        },
        {
          url: require("../assets/tabbar/zu33.png"),
          name: "艺术馆",
          index: 3,
          id: 16,
        },
        {
          url: require("../assets/tabbar/zu44.png"),
          name: "科普馆",
          index: 4,
          id: 17,
        },
        {
          url: require("../assets/tabbar/zu55.png"),
          name: "党建馆",
          index: 5,
          id: 18,
        },
        {
          url: require("../assets/tabbar/zu66.png"),
          name: "院史馆",
          index: 6,
          id: 19,
        },
        {
          url: require("../assets/tabbar/zu77.png"),
          name: "校史馆",
          index: 7,
          id: 20,
        },
        {
          url: require("../assets/tabbar/zu88.png"),
          name: "全部",
          index: 0,
          id: 0,
        },
      ],
      getLoadNewFlag: false,
      showtabbar: true,
    };
  },
  created() {
    let querys = this.$route.query;
    let tel = this.$route.query.tel;
    let turnType = this.$route.query.turnType;
    console.log(querys, 96666666666666);

    //只有在小程序tabbar点击过来时才会触发隐藏公众号底部tabbar
    if (querys.istabbar == 1) {
      var that = this;
      var uas = navigator.userAgent.toLowerCase();
      if (uas.match(/MicroMessenger/i) == "micromessenger") {
        new Promise((resolve) => {
          wx.miniProgram.getEnv(function (res) {
            if (res.miniprogram) {
              console.log("在小程序里2");
              that.showtabbar = false;
              console.log(that.showtabbar, 6);
            } else {
              console.log("在微信里2");
            }
          });
        });
      }
    }
    // return
    this.getBanner();
    this.getlist();
    localStorage.setItem("classItem", JSON.stringify(this.listItem));
    this.token = localStorage.getItem("token");
  },
  mounted() {
    console.log(233333333);
    // return
    //  console.log(this);
    window.addEventListener("scroll", this.windowScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.windowScroll);
  },
  methods: {
    //banner
    toWebview(url, id) {
      //跳转webview页面前发起请求

      $api
        .addExhiVisit({
          token: this.token,
          web_url: url,
        })
        .then((res) => {
          if (res.data.error == 0) {
            window.location.href =
              url +
              "&visit_id=" +
              res.data.visit_id +
              "&token=" +
              this.token +
              "&tem_id_dy=" +
              id;
          }
        });
    },
    getBanner() {
      console.log($api);
      $api.getItem().then((res) => {
        // console.log(res);
        this.bannerList = res.data.bannerList;
      });
    },
    //list
    getlist() {
      this.pageNum.page = 1;
      $api
        .getdyExhListNew({
          token: this.token,
          page: this.pageNum.page,
          pageSize: this.pageNum.pageSize,
          platformtype: 1,
        })
        .then((res) => {
          console.log(res, "1111");
          this.totalCount = res.data.totalCount;
          this.exhList = res.data.exhList;
          localStorage.setItem("list", JSON.stringify(this.exhList));
        });
    },
    //分类跳转
    goToDetail(index, id, name) {
      console.log(index, id, name);
      this.$router.push({
        name: "exhibitionList",
        params: { index, id, name },
      });
    },
    //收藏/取消数字空间产品
    collect(k, item) {
      console.log(item, 1111111111);
      let is_collect = item.is_collect;

      console.log(is_collect);
      if (is_collect == 2) {
        var data = {
          id: item.tem_id_dy,
          type: 1,
          token: this.token,
          platformtype: 2,
        };
      }
      if (is_collect == 1) {
        data = {
          id: item.tem_id_dy,
          type: 2,
          token: this.token,
          platformtype: 2,
        };
      }
      console.log(data, "参数");
      $api.collect(data).then((res) => {
        console.log(res, "999999", this.token);
        if (res.data.error == 0) {
          console.log(res);
          // this.getlist();
          if (data.type == 1) {
            item.is_collect = 1;
          } else if (data.type == 2) {
            item.is_collect = 2;
          }
          Toast.success(`${res.data.errorMsg}!`);
        }
      });
    },
    //触底加载
    windowScroll() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop; // 滚动条滚动时，距离顶部的距离
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight; // 可视区的高度
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight; // 滚动条的总高度
      // console.log(scrollTop, windowHeight, scrollHeight);
      if (this.getLoadNewFlag == true) {
        return;
      } else {
        if (scrollTop + windowHeight >= scrollHeight - 1) {
          console.log(this.exhList.length, this.totalCount);
          if (this.exhList.length >= this.totalCount) {
            Toast("没有更多数据了");
            return;
          } else {
            Toast.loading({
              message: "正在加载更多...",
              forbidClick: true,
            });
            console.log(this.pageNum.page + 1, $api.getLoadNew);
            this.getLoadNewFlag = true;
            $api
              .getdyExhListNew({
                page: this.pageNum.page + 1,
                pageSize: this.pageNum.pageSize,
                platformtype: 1,
                token: this.token,
              })
              .then((res) => {
                console.log(this.exhList, res);
                if (res.data.error == 0) {
                  this.getLoadNewFlag = false;
                  this.exhList = [...this.exhList, ...res.data.exhList];
                  this.pageNum.page = this.pageNum.page + 1;
                  Toast.clear();
                }
                console.log(this.pageNum.page);
              });
          }
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.home {
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("../assets/tabbar/bgi.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  section {
    position: relative;
    .search {
      z-index: 2;
      position: absolute;
      display: flex;
      justify-content: space-around;
      align-items: center;
      top: 0.24rem;
      left: 1.35rem;
      background: rgba(255, 255, 255, 0.5);
      width: 4.8rem;
      height: 0.6rem;
      font-size: 0.26rem;
      border-radius: 0.3rem;
      line-height: 0.6rem;

      .ipt {
        height: 0.6rem;
        width: 3.07rem;
        line-height: 0.6rem;
        color: #ffffff;
        background: transparent;
        border: none;
      }

      img {
        width: 0.38rem;
        height: 0.38rem;
      }
    }
    .my-swipe .van-swipe-item img {
      width: 100vw;
      height: 44.2667vw;
    }
  }
  .center-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    top: -0.2rem;
    .radio-box {
      width: 100vw;
      height: 0.5rem;
      background: #f4f8ff;
      border-radius: 4rem 4rem 0px 0px / 0.2rem 0.2rem 0px 0px;
    }
    .list-item {
      margin: 0rem 0.4rem 0.4rem 0.4rem;
      text-align: center;
      img {
        width: 0.72rem;
        height: 0.72rem;
        margin: 0.12rem;
      }
      .name {
        font-size: 0.26rem;
      }
    }
  }
  .bottom-countant {
    padding-bottom: 1.5rem;
    .title-count {
      display: flex;
      align-items: center;
      margin: 0 0 0.31rem 0.31rem;
      font-weight: bolder;
      padding-top: 0.29rem;

      .title-left-blue {
        width: 0.06rem;
        height: 0.3rem;
        background-color: rgb(38, 112, 255);
        margin-right: 0.19rem;
        border-radius: 0.03rem;
      }
      .title {
        font-size: 0.36rem;
      }
    }

    //产品介绍
    .list-contant {
      display: flex;
      flex-wrap: wrap;
      margin-left: 0.1rem;

      .content {
        width: 3.4rem;
        height: 3.02rem;
        margin: 0 0.13rem 0.5rem 0.13rem;
        position: relative;

        .web-item {
          background-color: #fff;
          border-radius: 0.14rem;
          position: relative;
          .is-shoucang {
            position: absolute;
            top: 0;
            right: 0.3rem;
            width: 0.44rem;
            height: 0.46rem;
          }
          .img-box {
            border-radius: 0.14rem 0.14rem 0 0;
            width: 3.4rem;
            height: 1.86rem;
            overflow: hidden;
            -webkit-transform: rotate(0deg);

            img {
              width: 3.4rem;
              height: 1.86rem;
            }

            img:active {
              transform: scale(1.3);
              transition: all 0.7s;
            }
          }

          .bottom-contant {
            height: 1.45rem;
            box-shadow: 0 0 0.2rem #f3f3f3;
            .contant-name {
              font-size: 0.3rem;
              font-weight: bolder;
              margin: 0rem 0 0 0.12rem;
              color: #1a1a1a;
              padding-top: 0.18rem;
            }

            .contant-publisher {
              font-size: 0.2rem;
              margin: 0.15rem 0 0.11rem 0.12rem;
              color: #999999;
            }

            .state {
              display: flex;
              .state-contant {
                display: flex;
                line-height: 0.25rem;
                width: 2.61rem;
                height: 0.2rem;
                margin-left: 0.12rem;
                // padding-bottom: 20rpx;
              }

              .state-liulan {
                img {
                  width: 0.24rem;
                  height: 0.24rem;
                  margin-right: 0.08rem;
                }
              }

              .state-shoucang {
                img {
                  width: 0.21rem;
                  height: 0.21rem;
                  margin-right: 0.08rem;
                }
              }

              .count {
                font-size: 0.22rem;
                color: #999999;
              }
            }
          }
        }
      }
    }
  }
}
</style>
