import wx from 'weixin-js-sdk'
import api from './api'
function shareWx(title, desc, link, imgUrl,token,tem_id){
    const url = window.location.href;
    api.getWxConfig({
        url:url
    }).then(res=>{
        wx.config({
            appId: res.data.appid, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的 JS 接口列表
        });
        wx.ready(rs => {
        // 分享给朋友
            wx.updateAppMessageShareData({
                title: title || '分享标题', // 分享标题
                desc: desc || '分享描述', // 分享描述
                link: link ||'分享链接', // 分享链接
                imgUrl: imgUrl || 'https://vip-flow.luboedu.com/listener/%E5%88%86%E4%BA%AB%E9%93%BE%E6%8E%A502.png', // 分享图标
                type: '', // 分享类型,music、video或link，不填默认为link
                success: function (r) {
                    // 用户点击了分享后执行的回调函数
                    api.onUshare({
                        token:token,
                        tem_id:tem_id
                    })
                }
            })
            // 分享到朋友圈
            wx.updateTimelineShareData({
                title: title || '分享标题', // 分享标题
                desc: desc || '分享描述', // 分享描述
                link: link ||'分享链接', // 分享链接
                imgUrl: imgUrl | 'https://vip-flow.luboedu.com/listener/%E5%88%86%E4%BA%AB%E9%93%BE%E6%8E%A502.png', // 分享图标
                type: '', // 分享类型,music、video或link，不填默认为link
                dataUrl: '', // 如果type是music或video，则要提供数据链接，默认为空
                success: function (r) {
                    // 用户点击了分享后执行的回调函数
                }
            })
        })
    }) 
}
export default shareWx