import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: '首页',
      needLogin: true
    },
  },
  {
    path: '/shopp',
    name: 'shopp',
    component: () => import('../views/ShoppView'),
    meta: {
      title: '商城',
      needLogin: false
    },
  },
  {
    path: '/car',
    name: 'car',
    component: () => import('../views/CarView'),
    meta: {
      title: '购物车',
      needLogin: true
    },
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('../views/MyView'),
    meta: {
      title: '个人中心',
      needLogin: true
    },
  }, {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login'),
    meta: {
      title: '登录',
      needLogin: false
    },
  }, {
    path: '/myVip',
    name: 'myVip',
    component: () => import('../views/My/myVip'),
    meta: {
      title: '会员中心',
      needLogin: true
    },
  }, {
    path: '/myWallet',
    name: 'myWallet',
    component: () => import('../views/My/myWallet'),
    meta: {
      title: '我的余额',
      needLogin: true
    },
  }, {
    path: '/myCollect',
    name: 'myCollect',
    component: () => import('../views/My/myCollect'),
    meta: {
      title: '我的收藏',
      needLogin: true
    },
  }, {
    path: '/myExhibition',
    name: 'myExhibition',
    component: () => import('../views/My/myExhibition'),
    meta: {
      title: '我的元宇宙',
      needLogin: true
    },
  }, {
    path: '/compile',
    name: 'compile',
    component: () => import('../views/My/compile'),
    meta: {
      title: '编辑我的元宇宙',
      needLogin: true
    },
  },
  {
    path: '/exhibitionList',
    name: 'exhibitionList',
    component: () => import('../views/home/exhibitionList'),
    meta: {
      title: '我的元宇宙分类',
      needLogin: true
    },
  },
  {
    path: '/configuration',
    name: 'configuration',
    component: () => import('../views/My/configuration'),
    meta: {
      title: '布置我的元宇宙',
      needLogin: true
    },
  }, {
    path: '/shareFriends',
    name: 'shareFriends',
    component: () => import('../views/My/shareFriends'),
    meta: {
      title: '推广列表',
      needLogin: true
    },
  }, {
    path: '/information',
    name: 'information',
    component: () => import('../views/My/information'),
    meta: {
      title: '元宇宙数据统计',
      needLogin: true
    },
  }, {
    path: '/aboutOurse',
    name: 'aboutOurse',
    component: () => import('../views/My/aboutOurse'),
    meta: {
      title: '关于我们',
      needLogin: true
    }
  }, {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('../views/shopp/orderDetail'),
    meta: {
      title: '订单详情',
      needLogin: true
    },
  }, {
    path: '/orderHistory',
    name: 'orderHistory',
    component: () => import('../views/shopp/orderHistory'),
    meta: {
      title: '订单记录',
      needLogin: true
    },
  }, {
    path: '/dataStatistics',
    name: 'dataStatistics',
    component: () => import('../views/dataStatistics/dataStatistics'),
    meta: {
      title: '元宇宙数据分析',
      needLogin: true
    },
  }, {
    path: '/test',
    name: 'test',
    component: () => import('../views/test'),
    meta: {
      title: '测试',
      needLogin: false
    },
  }, {
    path: '/testcompile',
    name: 'testcompile',
    component: () => import('../views/My/testcompile'),
    meta: {
      title: '测试编辑拖拽功能',
      needLogin: false
    },
  }, {
    path: '/createAccount',
    name: 'createAccount',
    component: () => import('../views/createAccount'),
    meta: {
      title: '创建账号',
      needLogin: false
    },
  }, {
    path: '/myselfCenter',
    name: 'myselfCenter',
    component: () => import('../views/My/myselfCenter'),
    meta: {
      title: '个人中心',
      needLogin: true
    },
  }, {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('../views/My/selfCenter/changePassword'),
    meta: {
      title: '个人中心',
      needLogin: true
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  console.log(to,'========');
  if (to.matched.some(r => r.meta.needLogin)) {
    let token = localStorage.getItem('token')
    if (!token) {
      return next({
        name: 'login'
      })
    }
  }

  document.title = (to.matched.filter(r => !!r.meta.title).map(r => r.meta.title) || [])[0] || document.title;
  return next()
})

export default router
