import wx from 'weixin-js-sdk'
import api from './api'
function wxapp(){
    const url = window.location.href;
    api.getWxConfig({
        url:url
    }).then(res=>{
        wx.config({
            appId: res.data.appid, // 必填，公众号的唯一标识
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的 JS 接口列表
            openTagList :['wx-open-launch-weapp']
        });
    }) 
}
export default wxapp