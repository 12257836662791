import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import Vconsole from 'vconsole'
import shareWx from './untils/share.js';
import wxapp from './untils/wxapp.js';
// 引入echarts
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts
Vue.prototype.shareWx = shareWx
Vue.prototype.wxapp = wxapp
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.prototype.$axios = axios;
Vue.prototype.appId = 'wx0c1c3917911bccf8';
Vue.prototype.redirect_uri = 'https://wechatoa.ky3d.com/login';
Vue.prototype.redirect_uri_shop = 'https://wechatoa.ky3d.com/shopp';
// document.documentElement.style.fontSize = document.documentElement.clientWidth / 750 * 100 + 'px';
Vue.use(Vant);
Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']
// let vConsole = new Vconsole()
// Vue.use(vConsole)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
