import http from '../untils/http'
export default {
    //轮播图数据
    getItem(params) {
        return http.post('/api' + '/dyBanner', params)
    },
    //场馆列表数据
    getdyExhListNew(params) {
        return http.post('/api' + '/dyExhListNew', params)
    },
    // //触底加载数据
    // getLoadNew(params) {
    //     return http.post('/api' + '/dyExhListNew', params)
    // },
    //收藏场馆列表
    dyCollect(params) {
        return http.post('/api' + '/dyCollect', params)
    },
    //获取抖音分类数据
    dyExhListNew(params) {
        return http.post('/develop' + '/dyExhListNew', params)
    },
    //请求验证码接口
    getVerifyCode(params) {
        return http.post('/wechatoa' + '/getVerifyCode', params)
    },
    //登录接口
    login(params) {
        return http.post('/wechatoa' + '/login', params)
    },
    //通过code换取openid
    getOpenid(params) {
        return http.post('/wechatoa' + '/getOpenid', params)
    },
    //注册接口
    register(params) {
        return http.post('/wechatoa' + '/register', params)
    },
    //支付接口
    pay(params) {
        return http.post('/wechatjs' + '/indexNew', params)
    },
    //获取所有展馆接口
    getExhibitionList(params) {
        return http.post('/wechatoa' + '/getExhibitionList', params)
    },
    //购买展馆接口
    buyExhibition(params) {
        return http.post('/wechatoa' + '/buyExhibition', params)
    },
    //上传文件接口
    uploadFile(params) {
        return http.post('/wechatoa' + '/uploadFile', params)
    },
    //获取当前展馆的订单
    getExhibitionOrderDetail(params) {
        return http.post('/wechatoa' + '/getExhibitionOrderDetail', params)
    },
    //获取当前用户展馆列表
    getPersonExhibitionList(params) {
        return http.post('/wechatoa' + '/getPersonExhibitionList', params)
    },
    //获取当前所有订单列表
    orderList(params) {
        return http.post('/wechatoa' + '/orderList', params)
    },
    //获取微信配置
    getWxConfig(params) {
        return http.post('/wechatoa' + '/getWxConfig', params)
    },
    //获取用户信息
    getUserInfo(params) {
        return http.post('/wechatoa' + '/getUserInfo', params)
    },
    //点击收藏  1  收藏   2取消收藏
    collect(params) {
        return http.post('/wechatoa' + '/dyCollect', params)
    },
    //收藏列表
    getMyColletList(params) {
        return http.post('/wechatoa' + '/getMyColletList', params)
    },
    //收藏列表
    getConfigurationList(params) {
        return http.post('/wechatoa' + '/getConfigurationList', params)
    },
    addConfiguration(params) {
        return http.post('/wechatoa' + '/addConfiguration', params)
    },
    //修改配置表
    editConfiguration(params) {
        return http.post('/wechatoa' + '/editConfiguration', params)
    },
    //删除配置表
    deleteConfiguration(params) {
        return http.post('/wechatoa' + '/deleteConfiguration', params)
    },
    sendConfiguration(params) {
        return http.post('/wechatoa' + '/sendConfiguration', params)
    },
    //访问记录接口
    getExhivisitList(params) {
        return http.post('/wechatoa' + '/getExhivisitList', params)
    },
    //获取展馆详情
    getExhiInfo(params) {
        return http.post('/wechatoa' + '/getExhiInfo', params)
    },
    //增加访问记录
    addExhiVisit(params) {
        return http.post('/wechatoa' + '/addExhiVisit', params)
    },
    //访问者分享受访者展馆  统计分享记录
    onUshare(params) {
        return http.post('/wechatoa' + '/onUshare', params)
    },
    //全选取消收藏
    cancelMyCollect(params) {
        return http.post('/wechatoa' + '/cancelMyCollect', params)
    },
    getLocateList(params) {
        return http.post('/wechatoa' + '/getLocateList', params)
    },
    getPersonExhibitionMsg(params) {
        return http.post('/wechatoa' + '/getPersonExhibitionMsg', params)
    },
    getExhiConfigInfo(params) {
        return http.post('/wechatoa' + '/getExhiConfigInfo', params)
    },
    // onUshare(params) {
    //     return http.post('/wechatoa' + '/onUshare', params)
    // },
    uploadImgOnlyOne(params) {
        return http.post('/wechatoa' + '/uploadImgOnlyOne', params)
    },
    verifyTel(params) {
        return http.post('/wechatoa' + '/verifyTel', params)
    },
    autoRegister(params) {
        return http.post('/wechatoa' + '/autoRegister', params)
    },
    registerAccount(params) {
        return http.post('/wechatoa' + '/registerAccount', params)
    },
    getUdata(params){
        return http.post('/wechatoa' + '/getUdata', params)
    },
    editPassWord(params){
        return http.post('/wechatoa' + '/editPassWord', params)
    }
}

