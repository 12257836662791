<template>
  <van-tabbar v-model="active" route>
    <van-tabbar-item to="/">
      <span>首页</span>
      <template #icon="props">
        <img :src="props.active ? icon.active : icon.inactive" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item to="/shopp">
      <span>商城</span>
      <template #icon="props">
        <img :src="props.active ? icon.active1 : icon.inactive1" />
      </template>
    </van-tabbar-item>
    <!-- <van-tabbar-item to="/car">
      <span>购物车</span>
      <template #icon="props">
        <img :src="props.active ? icon.active2 : icon.inactive2" />
      </template>
    </van-tabbar-item> -->
    <van-tabbar-item to="/my">
      <span>我的</span>
      <template #icon="props">
        <img :src="props.active ? icon.active3 : icon.inactive3" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      icon: {
        active: require("../assets/tabbar/shouye1.png"),
        inactive: require("../assets/tabbar/shouye2.png"),
        active1: require("../assets/tabbar/shangcheng1.png"),
        inactive1: require("../assets/tabbar/shangcheng2.png"),
        active2: require("../assets/tabbar/gouwuche1.png"),
        inactive2: require("../assets/tabbar/gouwuche2.png"),
        active3: require("../assets/tabbar/wode1.png"),
        inactive3: require("../assets/tabbar/wode2.png"),
      },
    };
  },
};
</script>

<style scoped lang="scss">
van-tabbar {
  van-tabbar-item {
    width: 0.7rem;
    img {
      width: 46rem;
      height: 0.46rem;
    }
  }
}
</style>
